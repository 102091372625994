<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="20.971px" viewBox="1.5 1.5 22 20.971">
    <polygon class="cls-1" :class="{'filled' : fill}" points="12.5,2 15.744,8.573 23,9.633 17.75,14.748 18.988,21.971 12.5,18.558 6.011,21.971 7.25,14.748 2,9.633 9.256,8.573 "/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconFeatherStar = Vue.extend({
  name: 'icon-feather-star',
  props: {
    color: {
      type: String,
      default: '#00B6F8',
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
});
export default IconFeatherStar;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1 {
    fill: none;
    stroke: $ew-blue;
  }

  .filled {
    fill: $ew-blue;
  }
</style>
