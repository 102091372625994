<template>
  <svg width="88" height="90" viewBox="0 0 88 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M75.7437 41.1543V18.4556C75.7437 13.0608 71.326 8.605 65.9308 8.605H64.7086V7.06629C64.7086 3.2172 61.5882 0.0967985 57.7391 0.0967985C53.89 0.0967985 50.7696 3.2172 50.7696 7.06629V8.605H25.2148V7.06629C25.2148 3.16376 22.051 0 18.1485 0C14.246 0 11.0822 3.16376 11.0822 7.06629V8.605H10.0261C4.63118 8.605 0.240784 13.0608 0.240784 18.4556V70.5766C0.240784 75.9716 4.63118 80.4295 10.0261 80.4295H40.7295C45.7915 86.4845 53.2736 89.9884 61.1658 90C75.8211 90 87.7593 78.0595 87.7593 63.4038C87.7597 54.1148 82.9068 45.9079 75.7437 41.1543ZM54.6416 7.06629C54.6118 5.38006 55.9544 3.98887 57.6406 3.95906C57.6677 3.95867 57.6948 3.95848 57.7219 3.95867C59.424 3.94067 60.8187 5.30592 60.8367 7.00821C60.8369 7.02757 60.8369 7.04693 60.8367 7.06629V14.6065H54.6416V7.06629ZM14.9542 7.06629C14.9726 5.33147 16.3939 3.94009 18.1288 3.95848C18.1319 3.95848 18.1348 3.95848 18.1377 3.95867C19.8789 3.95867 21.3429 5.32528 21.3429 7.06629V14.6065H14.9542V7.06629ZM4.11273 18.4556C4.11273 15.1956 6.76617 12.4769 10.0261 12.4769H11.0822V16.623C11.0822 17.6921 11.9751 18.4785 13.0443 18.4785H23.2314C24.3005 18.4785 25.2148 17.6921 25.2148 16.623V12.4769H50.7696V16.623C50.741 17.6191 51.5252 18.4498 52.5213 18.4785C52.5569 18.4794 52.5925 18.4794 52.6282 18.4785H62.8152C63.8268 18.5121 64.6742 17.7194 64.7079 16.7078C64.7088 16.6796 64.709 16.6513 64.7086 16.623V12.4769H65.9308C69.2129 12.51 71.8593 15.1736 71.8717 18.4556V24.6736H4.11273V18.4556ZM10.0261 76.5576C6.76617 76.5576 4.11273 73.8366 4.11273 70.5766V28.5455H71.8717V39.0489C68.5018 37.5693 64.861 36.8061 61.1805 36.8075C46.5252 36.8075 34.5944 48.7654 34.5944 63.4211C34.587 68.0254 35.7771 72.5523 38.048 76.5576H10.0261ZM61.1658 86.0936C48.625 86.0936 38.4586 75.9272 38.4586 63.3864C38.4586 50.8456 48.625 40.6792 61.1658 40.6792C73.7066 40.6792 83.873 50.8456 83.873 63.3864V63.3866C83.8589 75.9214 73.7008 86.0795 61.1658 86.0936Z"
      fill="#00B6F8"/>
    <path
      d="M70.2862 63.3929H61.8047V51.7442C61.8047 50.675 60.938 49.8082 59.8688 49.8082C58.7995 49.8082 57.9328 50.675 57.9328 51.7442V65.327C57.9498 66.4036 58.8284 67.2668 59.9052 67.2649H70.2862C71.3555 67.2649 72.2222 66.3981 72.2222 65.3289C72.2222 64.2597 71.3555 63.3929 70.2862 63.3929Z"
      fill="#00B6F8"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconSchedule = Vue.extend({
  name: 'icon-schedule',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color};`;
    },
  },
});

export default IconSchedule;
</script>
