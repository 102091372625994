<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21.004px" viewBox="0 -0.203 21 21.004" >
    <g class="cls-1" transform="translate(-5.5 -7.5)">
      <path d="M6,15.383v9.936c0.005,1.377,1.124,2.487,2.5,2.482h15 c1.377,0.005,2.495-1.105,2.5-2.482v-9.936"/>
      <path d="M21.031,12.968L16,8l-5.032,4.968"/>
      <path d="M16,8v16.147"/>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconFeatherShare = Vue.extend({
  name: 'icon-feather-share',
});
export default IconFeatherShare;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1 {
    fill: none;
    stroke: $ew-black;
  }
</style>
